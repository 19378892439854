import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h1 style={styles.errorCode}>404</h1>
      <h2 style={styles.heading}>Page Not Found</h2>
      <p style={styles.message}>
        Oops! The page you're looking for doesn't exist or has been moved.
      </p>
      <button onClick={() => navigate("/portal/product-management")} style={styles.button}>
        Go Back Home
      </button>
    </div>
  );
};

// Apply styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // Full viewport height
    overflow: 'hidden', // Prevents container from adding scroll
    backgroundColor: '#f8f9fa',
    color: '#343a40',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    padding: '1rem',
    boxSizing: 'border-box',
  },
  errorCode: {
    fontSize: 'clamp(4rem, 8vw, 6rem)',
    fontWeight: 'bold',
    color: '#5f74ff',
    animation: 'pulse 1.5s infinite',
    margin: 0,
  },
  heading: {
    fontSize: 'clamp(1.5rem, 4vw, 2rem)',
    marginBottom: '1rem',
    color: '#495057',
    margin: 0,
  },
  message: {
    fontSize: 'clamp(1rem, 3vw, 1.25rem)',
    marginBottom: '2rem',
    color: '#868e96',
    maxWidth: '600px',
  },
  button: {
    padding: '0.75rem 1.5rem',
    fontSize: 'clamp(0.9rem, 2.5vw, 1rem)',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#5f74ff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

// CSS keyframe animation for a subtle pulsing effect
const pulseAnimation = `
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}
`;

// Injecting keyframe animation into the document
document.head.insertAdjacentHTML("beforeend", `<style>${pulseAnimation}</style>`);

// Ensure no overflow
document.body.style.overflow = 'hidden';

export default NotFound;