import { createContext, useState, useEffect } from 'react';
import axios from "axios";
import { tokenRoute } from '../utils/APIRoutes';

// Create the context
export const CsrfTokenContext = createContext();

// Provider component to wrap your application
export const CsrfTokenProvider = ({ children }) => {
    const [csrfToken, setCsrfToken] = useState('');

    const fetchCsrfToken = async () => {
        try {
            const { data } = await axios.get(tokenRoute, { withCredentials: true });
            setCsrfToken(data.csrfToken);
        } catch (error) {
            console.error('Failed to fetch CSRF token', error);
        }
    };

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const { data } = await axios.get(tokenRoute, { withCredentials: true });
                setCsrfToken(data.csrfToken);
            } catch (error) {
                console.error('Failed to fetch CSRF token', error);
            }
        };

        fetchCsrfToken(); // Fetch token once when the component mounts
    }, []);

    return (
        <CsrfTokenContext.Provider value={{ csrfToken, fetchCsrfToken }}>
            {children}
        </CsrfTokenContext.Provider>
    );
};