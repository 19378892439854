import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CsrfTokenContext } from './csrfContext';
import axios from "axios";
import { logOutRoute } from '../utils/APIRoutes';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../assets/styles/nav.css';
import logo from '../assets/images/logo.png';
import Swal from 'sweetalert2';

const Navigation = ({ sidebarOpen, toggleSidebar}) => {
  const { csrfToken } = useContext(CsrfTokenContext);
  const isLoggedIn = window.localStorage.getItem("user");
  const [enablePage, setEnablePage] = useState("none");
  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();

  const handleLinkClick = (id) => {
    setActiveLink(id);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setEnablePage("block");
      setActiveLink('adminDash'); 

    } else {
      setEnablePage("none");
    }
  }, [isLoggedIn]);

  const LogOut = async () => {
    await Swal.fire({
      title: 'Are you sure?',
      text: "You will need to log in again.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios.post(logOutRoute, {}, {
          headers: {
            'X-CSRF-Token': csrfToken,
          },
          withCredentials: true,
        });

        window.localStorage.clear();
        navigate("/portal/login");
      }
    });
  };

  return (
    <nav
      className={`adminNavbar ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}
      style={{ display: enablePage }}
    >
      <img
        src={logo}
        id="logonav"
        alt="Logo"
        style={{ display: sidebarOpen ? 'block' : 'none' }}
      />

      <button onClick={toggleSidebar} className="btnCloseSidebar">
        <i className="bi bi-layout-sidebar"></i>
      </button>
      <div className='liContainer'>
        <ul>
          <Link to="/portal/product-management" id="adminProductManagementLink">
            <li className={activeLink === 'adminProductManagementLink' ? 'active' : ''} onClick={() => handleLinkClick('adminProductManagementLink')}>
              <i className="bi bi-grid-fill"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}>Product Management</span>
            </li>
          </Link>

          <Link to="/portal/stock-management" id="adminStock">
            <li className={activeLink === 'adminStock' ? 'active' : ''} onClick={() => handleLinkClick('adminStock')}>
              <i className="bi bi-archive-fill"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}>Stock Management</span>
            </li>
          </Link>

          <Link to="/portal/transaction" id="adminTransaction">
            <li className={activeLink === 'adminTransaction' ? 'active' : ''} onClick={() => handleLinkClick('adminTransaction')}>
              <i className="bi bi-clipboard-fill"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}>Transaction</span>
            </li>
          </Link>

          <Link to="/portal/logs-page" id="adminLogPage">
            <li className={activeLink === 'adminLogPage' ? 'active' : ''} onClick={() => handleLinkClick('adminLogPage')}>
              <i className="bi bi-file-text-fill"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}>Logs</span>
            </li>
          </Link>

          <Link to="/portal/reports" id="adminReports">
            <li className={activeLink === 'adminReports' ? 'active' : ''} onClick={() => handleLinkClick('adminReports')}>
              <i className="bi bi-clipboard2-data-fill"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}>Reports</span>
            </li>
          </Link>

          <hr/>
          <li>
            <div id="adminLogoutLink" onClick={LogOut}>
              <i className="bi bi-box-arrow-right"></i>
              <span style={{ display: sidebarOpen ? 'inline' : 'none' }}> Log out</span>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
