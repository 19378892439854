import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CsrfTokenContext } from './csrfContext.jsx';
import axios from "axios";
import { fetchProductsDataRoute, createProductRoute, createCategoryRoute, updateCategoryRoute, disableEnableCategoryRoute } from '../utils/APIRoutes';
import Swal from 'sweetalert2';
import '../assets/styles/productManagement.css';
import ProductCard from '../components/productCard.jsx';
import { TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 

const AdminProductManagement = () => {
    const { csrfToken } = useContext(CsrfTokenContext);
    // for filter and set product
    const [activeTab, setActiveTab] = useState('products');
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isCategoryFormVisible, setIsCategoryFormVisible] = useState(false);
    const [isUpdateCategoryFormVisible, setIsUpdateCategoryFormVisible] = useState(false);
    const [categoryToUpdate, setCategoryToUpdate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [newProduct, setNewProduct] = useState({
        productName: '',
        productCode: '', 
        categories: '',
        productPrices: '',
        productStocks: '',
        description: ''
    });
    const [price30x30, setPrice30x30] = useState(0);
    const [price40x40, setPrice40x40] = useState(0);
    const [price60x60, setPrice60x60] = useState(0);
    const [imageFile, setNewImageFile] = useState();
    const [categoryName, setCategoryName] = useState('');

    const isLoggedIn = window.localStorage.getItem("user");
    const navigate = useNavigate();
    const [enablePage, setEnablePage] = useState("none");
    const [productPage, setProductPage] = useState(1);
    const [currentCategoryPage, setCategoryPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (isLoggedIn) {
            //navigate("/portal/product-management");
            document.title = "JCJHomeBuilders Portal - Product Management"
            setEnablePage("block");
        } else {
            setEnablePage("none");
            navigate("/portal/login");
            document.title = "JCJHomeBuilders Portal - Log In"
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            // Fetch logic from database.
            let productsData = [];
            let categoryData = [];
            setIsLoading(true);

            try {
                const { data } = await axios.get(fetchProductsDataRoute, { withCredentials: true });

                if (data.status === true) {      
                    for (let i = data.productsData.length - 1; i >= 0; i--) {
                        let productObj = { 
                            id: data.productsData[i].productId, 
                            code: data.productsData[i].productCode,
                            name: data.productsData[i].productName, 
                            prices: data.productsData[i].prices, 
                            image: data.productsData[i].imagePath, 
                            sizes: data.productsData[i].sizes, 
                            categories: data.productsData[i].categories, 
                            stocks: data.productsData[i].stocks,
                            status: data.productsData[i].disabled,
                            description: data.productsData[i].description
                        }

                        productsData.push(productObj);
                    }

                    for (let i = 0; i < data.categoryData.length; i++) {
                        let categoryObj = { 
                            id: data.categoryData[i].categoryId, 
                            name: data.categoryData[i].categoryName,
                            status: data.categoryData[i].disabled
                        }

                        categoryData.push(categoryObj);
                    }

                    setProducts(productsData);
                    setCategories(categoryData);
                    setIsLoading(false);
                } else {
                    Swal.fire(
                        "Error!",
                        data.msg,
                        "error"
                    );
                }
            } catch (error) {
                // Check if the error response exists (server returned a response)
                if (error.response) {
                    const status = error.response.status;
                    const responseData = error.response.data; // The server's response data

                    // Handle different status codes
                    if (status === 403 || status === 401) {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Access Denied!',
                            text: 'Your session may have expired.',
                            timer: 3000,
                            timerProgressBar: true
                        });

                        window.localStorage.clear();
                        navigate('/portal/login');
                    } else if (status === 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Not Found!',
                            text: responseData.msg || 'The requested resource could not be found.',
                        });
                    } else {
                        // Handle other errors or status codes
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: responseData.msg || 'An unexpected error occurred.',
                        });
                    }
                } else if (error.request) {
                    // If no response was received from the server
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Error!',
                        text: 'No response from the server. Please try again later.',
                    });
                } else {
                    // Handle any other errors during the request setup
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Something went wrong. Please try again.',
                    });
                }
            }
        }

        fetchData();
    }, [csrfToken, navigate]);

    useEffect(() => {
        setProductPage(1);
    }, [searchTerm, categoryFilter, statusFilter]);

    // Separate fetch function for refresh.
    const fetchData = async () => {
        // Fetch logic from database
        let productsData = [];
        let categoryData = [];
        setIsLoading(true);

        try {
            const { data } = await axios.get(fetchProductsDataRoute, { withCredentials: true });

            if (data.status === true) {      
                for (let i = data.productsData.length - 1; i >= 0; i--) {
                    let productObj = { 
                        id: data.productsData[i].productId, 
                        code: data.productsData[i].productCode,
                        name: data.productsData[i].productName, 
                        prices: data.productsData[i].prices, 
                        image: data.productsData[i].imagePath, 
                        sizes: data.productsData[i].sizes, 
                        categories: data.productsData[i].categories, 
                        stocks: data.productsData[i].stocks,
                        status: data.productsData[i].disabled,
                        description: data.productsData[i].description
                    }

                    productsData.push(productObj);
                }

                for (let i = 0; i < data.categoryData.length; i++) {
                    let categoryObj = { 
                        id: data.categoryData[i].categoryId, 
                        name: data.categoryData[i].categoryName,
                        status: data.categoryData[i].disabled
                    }

                    categoryData.push(categoryObj);
                }

                setProducts(productsData);
                setCategories(categoryData);
                setIsLoading(false);
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403 || status === 401) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: responseData.msg || 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    }

    const handleCategoryFilterChange = (event) => {
        setCategoryFilter(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleAddCategory = () => {
        setIsCategoryFormVisible(true);
    };

    const handleUpdateCategory = async (categoryId) => {
        const category = categories.find(cat => cat.id === categoryId);
        setCategoryToUpdate(category);
        setIsUpdateCategoryFormVisible(true);
    };

    const filteredProducts = products.filter(product => {
        const matchesSearchTerm = searchTerm === '' || product.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = categoryFilter === '' || product.categories.includes(categoryFilter);
        const matchesStatus = statusFilter === '' || 
            (statusFilter === 'enabled' && product.status === 'false') || 
            (statusFilter === 'disabled' && product.status === 'true');
    
        return matchesSearchTerm && matchesCategory && matchesStatus;
    });


    // Paginate the filtered products
    const paginatedProducts = filteredProducts.slice(
        (productPage - 1) * itemsPerPage,
        productPage * itemsPerPage
    );

    // Calculate total pages based on filtered products
    const totalProductPages = Math.max(1, Math.ceil(filteredProducts.length / itemsPerPage));

    // Reset page to 1 on filter change
    useEffect(() => {
        setProductPage(1); // Reset to the first page when filters change
    }, [categoryFilter, statusFilter, searchTerm]);

    // Calculate the current page data to display
    const paginatedCategories = categories.slice(
        (currentCategoryPage - 1) * itemsPerPage,
        currentCategoryPage * itemsPerPage
    );

    // Calculate total pages
    const totalPages = Math.max(1, Math.ceil(categories.length / itemsPerPage));
    // Pagination controls
    const goToNextProductPage = () => setProductPage(productPage + 1);
    const goToPrevProductPage = () => setProductPage(productPage - 1);
    const goToNextPage = () => setCategoryPage(prevPage => Math.min(prevPage + 1, totalPages));
    const goToPrevPage = () => setCategoryPage(prevPage => Math.max(prevPage - 1, 1));

    const setButtonState = (button, isDisabled, backgroundColor = '', textColor = '', showSpinner = false) => {
        const buttonText = button.querySelector('.button-text');
        const spinner = button.querySelector('.spinner');
    
        button.disabled = isDisabled;
        button.style.backgroundColor = backgroundColor;
        button.style.color = textColor;
    
        buttonText.style.display = showSpinner ? 'none' : 'inline-block';
        spinner.style.display = showSpinner ? 'inline-block' : 'none';
    };

    const handleUpdateCategorySubmit = async (event) => {
        event.preventDefault();

        const submitButton = event.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will update this category with the information you provided.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        // Update Category
        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();

        try {
            const { data } = await axios.post(updateCategoryRoute, { categoryToUpdate, _user, date }, {
                headers: {
                    'CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Category Updated Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                setIsUpdateCategoryFormVisible(false);
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Access Denied!',
                    //     text: responseData.msg || 'You are not authorized to access this resource.',
                    // });

                    closeCategoryForm();
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        setButtonState(submitButton, false, '', '', false);
    };

    const handleDisableCategory = async(categoryId, categoryName, action) => {
        // Enable/Disable Category
        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will ${action ? "disable" : "enable"} this category.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();

        try {
            const { data } = await axios.post(disableEnableCategoryRoute, { categoryId, categoryName, action, _user, date }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Category State Changed Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    const openForm = () => {
        setIsFormVisible(true);
    };

    const closeForm = () => {
        setIsFormVisible(false);
    };

    const closeCategoryForm = () => {
        setIsCategoryFormVisible(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewProduct({
            ...newProduct,
            [name]: value
        });
    };

    const handlePricesChange = (currentSize, value) => {
        let objPrices = { prices: [{ "30x30": price30x30 }, { "40x40": price40x40 }, { "60x60": price60x60 }] };

        objPrices.prices.forEach((price) => {
            if (Object.keys(price).toString() === currentSize) {
                price[currentSize] = value;
            }
        });

        setNewProduct({
            ...newProduct,
            productPrices: JSON.stringify(objPrices)
        });
    };

    // Custom handle for category changes in order to convert the array into an object and serialize.
    const handleCategoriesChange = (e) => {
        const categoriesObj = { categories: e };
        setNewProduct((prevData) => ({
            ...prevData,
            categories: JSON.stringify(categoriesObj),
        }));
    };

    const handleFileChange = (event) => {
        const { files } = event.target;
        setNewImageFile(files[0]);
    };

    const handleCategoryInputChange = (event) => {
        const { value } = event.target;
        setCategoryName(value);
    };

    const displayArr = (obj) => {
        return obj?.join(", ");
    }

    // PRODUCT SUBMIT
    const handleSubmit = async (event) => {
        event.preventDefault();

        const submitButton = event.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will create a new product with the information you provided.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        const pricesObj = JSON.parse(newProduct.productPrices);
        let currentSizes = [];
        let pricesValid = true;

        pricesObj.prices.forEach(price => {
            for (let size in price) {
                if (parseFloat(price[size]) <= 0) {
                    currentSizes.push(size);
                    pricesValid = false;
                }
            }
        });

        if (!pricesValid) {
            setButtonState(submitButton, false, '', '', false);

            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Error",
                text: `Price too low for ${displayArr(currentSizes)}. Please try again.`,
                showConfirmButton: false,
                timer: 3500
            });
        }

        if (imageFile?.size > 10485760) {
            setButtonState(submitButton, false, '', '', false);

            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Error',
                text: 'Image file too large, please keep it under 10MB.',
                showConfirmButton: false,
                timer: 3500
            });
        }

        // Create Product
        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const formData = new FormData();

        formData.append('image', imageFile);
        formData.append('productCode', newProduct.productCode);
        formData.append('productName', newProduct.productName);
        formData.append('categories', newProduct.categories);
        formData.append('productPrices', newProduct.productPrices);
        formData.append('description', newProduct.description);
        formData.append('_user', _user.username);
        formData.append('date', date);

        try {
            const { data } = await axios.post(createProductRoute, formData, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Product Created Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                closeForm();
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    closeForm();
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        setButtonState(submitButton, false, '', '', false);
    };

    const handleCategorySubmit = async (event) => {
        event.preventDefault();

        const submitButton = event.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will create a new category with the information you provided.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        // Create Category
        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();

        try {
            const { data } = await axios.post(createCategoryRoute, { categoryName, _user, date }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Category Created Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                closeCategoryForm();
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    closeCategoryForm();
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        setButtonState(submitButton, false, '', '', false);
    };

    // const sizes = ['30x30', '40x40', '60x60'];
    // const sizeData = sizes.map(size => ({
    //     label: size,
    //     value: size
    // }));

    // const [selectedSizes, setSelectedSizes] = useState([]);

    // // Handle selection changes
    // const handleSelectionChange = (selected) => {
    //     if (selected.includes('all')) {
    //         setSelectedSizes(sizeData.map(size => size.value));
    //     } else {
    //         setSelectedSizes(selected); 
    //         if (selected.length !== sizes.length) {
    //             setSelectedSizes(selected);
    //         }
    //         if (selected.length === sizes.length) {
    //             setSelectedSizes(sizeData.map(size => size.value));
    //         }
    //     }
    // };

    return (
        <div className="adminProductManagement" style={{ display: enablePage }} >
            <div className="topNavProductTitle">
                <h3>Product Management</h3>
            </div>
            <div className="tabs">
                <button className={activeTab === 'products' ? 'active' : ''} onClick={() => setActiveTab('products')}>Products</button>
                <button className={activeTab === 'category' ? 'active' : ''} onClick={() => setActiveTab('category')}>Category</button>
            </div>
            <div className='adminProductContainer'>
                {activeTab === 'products' && (
                    <div className="tabContent">
                        <div className='filterCardsContainer'>
                        <button className="addProductButton" onClick={openForm}>Add Product</button>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="searchBar"
                            />

                            <select value={categoryFilter} onChange={handleCategoryFilterChange}>
                                <option value=''>All Categories</option>
                                {categories.filter(category => {
                                    return category.status !== "true";
                                }).map(category => (
                                    <option key={category.id} value={category.id}>{category.name}</option>
                                ))}
                            </select>
                            <select value={statusFilter} onChange={handleStatusFilterChange}>
                                <option value=''>All Statuses</option>
                                <option value='enabled'>Enabled</option>
                                <option value='disabled'>Disabled</option>
                            </select>
                        </div>
                        <div className='borderPadCards'>
                            <div className='productCardsContainer'>
                                {isLoading ? <div className='loadingAnimationCon'><div className="spinner"></div> <p className='lblLoading'>Loading...</p></div> : null}
                                {paginatedProducts.length === 0 ? (
                                    <p>No products available.</p>
                                ) : (
                                    paginatedProducts.map(product => (
                                        <ProductCard key={product.id} product={product} categories={categories} fetchData={fetchData} />
                                    ))
                                )}
                            </div>
                        </div>
                        {/* Pagination Controls */}
                        <div className="pagination">
                            <button disabled={productPage === 1} onClick={goToPrevProductPage}>Previous</button>
                            <span>Page {productPage} of {totalProductPages}</span>
                            <button disabled={productPage === totalProductPages} onClick={goToNextProductPage}>Next</button>
                        </div>
                    </div>
                )}
                {activeTab === 'category' && (
                    <div className="tabContent categoryTableContainer">
                        <button className="addCategoryButton" onClick={handleAddCategory}>Add Category</button>
                        <table className="categoryTable">
                            <thead>
                                <tr>
                                    <th>Category Name</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedCategories.length === 0 ? (
                                    <tr>
                                        <td colSpan="3">No categories available.</td>
                                    </tr>
                                ) : (
                                    paginatedCategories.map(category => (
                                        <tr key={category.id}>
                                            <td>{category.name}</td>
                                            <td>{category.status === 'false' ? 'Enabled' : 'Disabled'}</td>
                                            <td>
                                                <div className="buttons">
                                                    <button className="updateButton" onClick={() => handleUpdateCategory(category.id)}>Update</button>
                                                    <button
                                                        className={category.status === 'false' ? 'disableButton' : 'enableButton'}
                                                        onClick={() => handleDisableCategory(category.id, category.name, category.status === "false")}
                                                    >
                                                        {category.status === 'false' ? 'Disable' : 'Enable'}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                        {/* Pagination Controls */}
                        <div className="pagination">
                            <button onClick={goToPrevPage} disabled={currentCategoryPage === 1}>Previous</button>
                            <span>Page {currentCategoryPage} of {totalPages}</span>
                            <button onClick={goToNextPage} disabled={currentCategoryPage === totalPages}>Next</button>
                        </div>
                    </div>
                )}
            </div>
            {/* POPUP FORMS */}
            {/* add product form */}
            {isFormVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Add Product</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Product Name:
                                <input type="text" name="productName" value={newProduct.name} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Product Code:
                                <input type="text" name="productCode" value={newProduct.productCode} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Product Description:
                                <textarea name="description" value={newProduct.description} onChange={handleInputChange} required> </textarea> 
                            </label>
                            <label>Category:</label>
                            <label className='productAddCategory'>
                                <TagPicker 
                                data={categories.filter(category => category.status !== "true").map(category => ({
                                    label: category.name,
                                    value: category.id
                                }))}
                                onChange={handleCategoriesChange}
                                style={{ 
                                    width: 1000, 
                                    padding: 7,
                                    display: 'flex', 
                                    alignItems: 'center',
                                }}                                
                                placeholder="Select Categories"
                                required
                                />
                            </label>
                            {/* <br />
                            <label>
                            <TagPicker
                                    data={[
                                        { label: 'Select All', value: 'all' },
                                        ...sizeData
                                    ]}
                                    value={selectedSizes}
                                    onChange={handleSelectionChange}
                                    style={{ width: 500 }}
                                    placeholder="Select Sizes"
                                    required
                                    searchable={false}
                                />
                            </label> */}
                            {/* <label>
                                Price:
                                <input type="number" step="0.01" name="productPrice" value={newProduct.price} onChange={handleInputChange} required />
                            </label> */}
                            <label>Prices</label>
                            <div className="sizeInputsContainer">
                                <div className="sizeInput">
                                    <label>
                                        Size 30x30:
                                        <input type="number" step="0.01" name="price30x30" onChange={(e) => {
                                                setPrice30x30(e.target.value);
                                                handlePricesChange("30x30", e.target.value)
                                            }
                                        } />
                                    </label>
                                </div>
                                <div className="sizeInput">
                                    <label>
                                        Size 40x40:
                                        <input type="number" step="0.01" name="price40x40" onChange={(e) => {
                                                setPrice40x40(e.target.value);
                                                handlePricesChange("40x40", e.target.value)
                                            }
                                        } />
                                    </label>
                                </div>
                                <div className="sizeInput">
                                    <label>
                                        Size 60x60:
                                        <input type="number" step="0.01" name="price60x60" onChange={(e) => {
                                                setPrice60x60(e.target.value);
                                                handlePricesChange("60x60", e.target.value)
                                            }
                                        } />
                                    </label>
                                </div>
                            </div>
                            <label>
                                Product Image:
                                <input type="file" name="image" accept="image/png, image/jpeg" onChange={handleFileChange} required />
                            </label>
                            <div className="formAddProductButtons">
                            <button type="submit">
                                    <span className="button-text">Submit</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>                                
                                <button type="button" className="btnClose" onClick={closeForm}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* add category form */}
            {isCategoryFormVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Add Category</h2>
                        <form className="categoryPopupForm" onSubmit={handleCategorySubmit}>
                            <label>
                                Category Name:
                                <input type="text" name="categoryName" value={categoryName} onChange={handleCategoryInputChange} required />
                            </label>
                            <div className="formButtons">
                            <button type="submit">
                                    <span className="button-text">Submit</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>
                                <button type="button" onClick={closeCategoryForm}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* update form for category */}
            {isUpdateCategoryFormVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Update Category</h2>
                        <form onSubmit={handleUpdateCategorySubmit}>
                            <label>
                                Category Name:
                                <input 
                                    type="text" 
                                    name="categoryName" 
                                    value={categoryToUpdate?.name || ''} 
                                    onChange={(e) => setCategoryToUpdate({ ...categoryToUpdate, name: e.target.value })} 
                                    required 
                                />
                            </label>
                            <div className="formButtons">
                            <button type="submit">
                                    <span className="button-text">Submit</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>                                
                                <button type="button" onClick={() => setIsUpdateCategoryFormVisible(false)}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </div>
    );
};

export default AdminProductManagement;