import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import LoginPage from './components/loginPage.jsx';
import ProductManagement from './components/productManagement.jsx';
import Navigation from './components/navigation.jsx';
import StockManagement from './components/stockmanagement.jsx';
import Transaction from './components/transaction.jsx';
import LogPage from './components/logPage.jsx';
import ReportPage from './components/reports.jsx';
import NotFound from './components/notFound.jsx';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'rsuite/dist/rsuite.min.css';

const validRoutes = [
  '/portal/product-management',
  '/portal/stock-management',
  '/portal/transaction',
  '/portal/logs-page',
  '/portal/reports'
];

const App = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

 // Check if the current route is valid
 const isValidRoute = validRoutes.includes(location.pathname);

  // Define routes where Navigation should be shown
  const showNavigation = isValidRoute && location.pathname !== '/portal/login';

  // excluding login page for sidebar
  const shouldShrink = sidebarOpen && location.pathname !== '/portal/login' && validRoutes;

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setSidebarOpen(false);
    }
  }, [location.pathname]);

  return (
    <div className={`app ${sidebarOpen ? 'sidebar-open' : ''}`}>
      
      {showNavigation && (
        <>
          <button
            onClick={toggleSidebar}
            className={`sidebar-toggle ${sidebarOpen ? 'hidden' : ''}`}
          >
            {sidebarOpen ? 'Close Sidebar' : <i className="bi bi-layout-sidebar"></i>}
          </button>
          <Navigation sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
        </>
      )}
      <div className={`main-content ${shouldShrink ? 'shrink' : ''}`}>
        <Routes>
          <Route exact path="/" element={<Navigate to="/portal/login" />} />
          <Route exact path="/portal/login" element={<LoginPage />} />
          <Route exact path="/portal/product-management" element={<ProductManagement />} />
          <Route exact path="/portal/stock-management" element={<StockManagement />} />
          <Route exact path="/portal/transaction" element={<Transaction />} />
          <Route exact path="/portal/logs-page" element={<LogPage />} />
          <Route exact path="/portal/reports" element={<ReportPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
