import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CsrfTokenContext } from './csrfContext';
import axios from "axios";
import { fetchStocksDataRoute, updateProductStockRoute, updateQuotationStatusRoute } from '../utils/APIRoutes';
import Swal from 'sweetalert2';
import '../assets/styles/transaction.css';
import { TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 

const TransactionPage = () => {
    const { csrfToken } = useContext(CsrfTokenContext);
    const [isDecreaseFormVisible, setIsDecreaseFormVisible] = useState(false);
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const [quotations, setQuotations] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const isLoggedIn = window.localStorage.getItem("user");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [enablePage, setEnablePage] = useState("none");
    const [searchStockTerm, setSearchStockTerm] = useState('');
    const [searchHistoryTerm, setSearchHistoryTerm] = useState('');
    const [activeTab, setActiveTab] = useState('stocks');
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [quantity30x30, setQuantity30x30] = useState(0);
    const [quantity40x40, setQuantity40x40] = useState(0);
    const [quantity60x60, setQuantity60x60] = useState(0);
    const [isManualDecreaseModalVisible, setIsManualDecreaseModalVisible] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [searchQuotationTerm, setSearchQuotationTerm] = useState('');
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editCartItem, setEditCartItem] = useState(null); // Stores the cart item to be edited
    const [isDisabled30x30, setIsDisabled30x30] = useState(true);
    const [isDisabled40x40, setIsDisabled40x40] = useState(true);
    const [isDisabled60x60, setIsDisabled60x60] = useState(true);
    const [selectedQuotation, setSelectedQuotation] = useState(null);
    const [isQuotationModalOpen, setIsQuotationModalOpen] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null); // To store the transaction selected for details
    const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false);
    const [transactionPage, setTransactionPage] = useState(1);
    const [quotationPage, setQuotationPage] = useState(1);
    const [productPage, setProductPage] = useState(1);
    const itemsPerPage = 10;
    const productsPerPage = 7;
    const numFormat = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    const [statusFilter, setStatusFilter] = useState(''); // New status filter state

    
    useEffect(() => {
        //isLoggedIn ? navigate("/") : navigate("/login");
        if (isLoggedIn) {
            //navigate("/portal/transaction");
            document.title = "JCJHomeBuilders Portal - Transactions"
            setEnablePage("block");
        } else {
            setEnablePage("none");
            navigate("/portal/login");
            document.title = "JCJHomeBuilders Portal - Log In"
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            // Fetch logic from database.
            let stocksData = [];

            try {
                setIsLoading(true);
                const { data } = await axios.get(fetchStocksDataRoute, { withCredentials: true });

                if (data.status === true) {      
                    for (let i = data.stocksData.length - 1; i >= 0; i--) {
                        let productObj = { 
                            id: data.stocksData[i].productId, 
                            code: data.stocksData[i].productCode,
                            name: data.stocksData[i].productName, 
                            sizes: data.stocksData[i].sizes,
                            categories: data.stocksData[i].categories, 
                            stocks: data.stocksData[i].stocks,
                            status: data.stocksData[i].disabled,
                            description: data.stocksData[i].description
                        }

                        stocksData.push(productObj);
                    }

                    setProducts(stocksData);
                    setQuotations(data.quotationsData)
                    setTransactions(data.transactionsData);
                    setIsLoading(false);
                } else {
                    Swal.fire(
                        "Error!",
                        data.msg,
                        "error"
                    );
                }
            } catch (error) {
                // Check if the error response exists (server returned a response)
                if (error.response) {
                    const status = error.response.status;
                    const responseData = error.response.data; // The server's response data
    
                    // Handle different status codes
                    if (status === 403 || status === 401) {
                        await Swal.fire({
                            icon: 'error',
                            title: 'Access Denied!',
                            text: 'Your session may have expired.',
                            timer: 3000,
                            timerProgressBar: true
                        });
    
                        window.localStorage.clear();
                        navigate('/portal/login');
                    } else if (status === 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Not Found!',
                            text: 'The requested resource could not be found.',
                        });
                    } else {
                        // Handle other errors or status codes
                        Swal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: responseData.msg || 'An unexpected error occurred.',
                        });
                    }
                } else if (error.request) {
                    // If no response was received from the server
                    Swal.fire({
                        icon: 'error',
                        title: 'Network Error!',
                        text: 'No response from the server. Please try again later.',
                    });
                } else {
                    // Handle any other errors during the request setup
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Something went wrong. Please try again.',
                    });
                }
            }
        };

        fetchData();
    }, [csrfToken, navigate]);

    useEffect(() => {
        setTransactionPage(1);
        setQuotationPage(1);
        setProductPage(1);
    }, [searchHistoryTerm, searchQuotationTerm, searchStockTerm, statusFilter]);

    const handleDecreaseStockClick = (product) => {
        setProduct(product);  // Set the product selected for stock decrease
        setIsDecreaseFormVisible(true);  // Show the modal form
    };
    
    //Refresh function.
    const fetchData = async () => {
        // Fetch logic from database.
        let stocksData = [];

        try {
            setIsLoading(true);
            const { data } = await axios.get(fetchStocksDataRoute, { withCredentials: true });

            if (data.status === true) {      
                for (let i = data.stocksData.length - 1; i >= 0; i--) {
                    let productObj = { 
                        id: data.stocksData[i].productId, 
                        code: data.stocksData[i].productCode,
                        name: data.stocksData[i].productName, 
                        sizes: data.stocksData[i].sizes,
                        categories: data.stocksData[i].categories, 
                        stocks: data.stocksData[i].stocks,
                        status: data.stocksData[i].disabled,
                        description: data.stocksData[i].description
                    }

                    stocksData.push(productObj);
                }

                setProducts(stocksData);
                setQuotations(data.quotationsData);
                setTransactions(data.transactionsData);
                setIsLoading(false);
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403 || status === 401) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    const filteredQuotations = quotations.filter(quotation => {
        const matchesSearchTerm =
            quotation.quotationId.toString().includes(searchQuotationTerm.toLowerCase()) ||
            (quotation.items.length > 0 && quotation.items[0].productCode.toLowerCase().includes(searchQuotationTerm.toLowerCase()));
        const matchesStatus = !statusFilter || quotation.status === statusFilter;
    
        return matchesSearchTerm && matchesStatus;
    });

    // Apply filter and then paginate the transactions data
    const filteredTransactions = transactions.filter(transaction =>
        transaction.transactionId.toString().includes(searchHistoryTerm.toLowerCase()) ||
        transaction.items.some(item => item.productCode.toLowerCase().includes(searchHistoryTerm.toLowerCase()))
    );

    const filteredProducts = products.filter((product) =>
        product.code.toLowerCase().includes(searchStockTerm.toLowerCase())
    );

    const paginatedQuotations = filteredQuotations.slice((quotationPage - 1) * itemsPerPage, quotationPage * itemsPerPage);
    const totalQuotationPages = Math.max(1, Math.ceil(filteredQuotations.length / itemsPerPage));

    const paginatedTransactions = filteredTransactions.slice((transactionPage - 1) * itemsPerPage, transactionPage * itemsPerPage);
    const totalTransactionPages = Math.max(1, Math.ceil(filteredTransactions.length / itemsPerPage));

    const paginatedProducts = filteredProducts.slice((productPage - 1) * productsPerPage, productPage * productsPerPage);
    const totalProductsPages = Math.max(1, Math.ceil(filteredProducts.length / itemsPerPage));

    // Pagination controls
    const goToNextTransactionPage = () => setTransactionPage(transactionPage + 1);
    const goToPrevTransactionPage = () => setTransactionPage(transactionPage - 1);
    const goToNextQuotationPage = () => setQuotationPage(quotationPage + 1);
    const goToPrevQuotationPage = () => setQuotationPage(quotationPage - 1);
    const goToNextProductPage = () => setProductPage(productPage + 1);
    const goToPrevProductPage = () => setProductPage(productPage - 1);

    // const setButtonState = (button, isDisabled, backgroundColor = '', textColor = '', showSpinner = false) => {
    //     const buttonText = button.querySelector('.button-text');
    //     const spinner = button.querySelector('.spinner');
    
    //     button.disabled = isDisabled;
    //     button.style.backgroundColor = backgroundColor;
    //     button.style.color = textColor;
    
    //     buttonText.style.display = showSpinner ? 'none' : 'inline-block';
    //     spinner.style.display = showSpinner ? 'inline-block' : 'none';
    // };

    const handleDecreaseSubmit = (e) => {
        e.preventDefault();
    
        if (selectedSizes.length === 0 || (!quantity30x30 && !quantity40x40 && !quantity60x60)) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one size and enter a quantity.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
    
        const updatedCart = [...cartItems]; // Make a copy of the current cart
    
        selectedSizes.forEach(size => {
            let quantity = 0;
            if (size === '30x30') {
                quantity = quantity30x30;
            } else if (size === '40x40') {
                quantity = quantity40x40;
            } else if (size === '60x60') {
                quantity = quantity60x60;
            }
    
            if (quantity > 0) {
                // Check if the item with the same product code and size already exists in the cart
                const existingCartItemIndex = updatedCart.findIndex(
                    (item) => item.code === product.code && item.size === size
                );
    
                if (existingCartItemIndex !== -1) {
                    // If the item exists, update its quantity
                    updatedCart[existingCartItemIndex].quantity = 
                        parseInt(updatedCart[existingCartItemIndex].quantity) + parseInt(quantity);
                } else {
                    // If the item doesn't exist, add it to the cart
                    updatedCart.push({
                        code: product.code,
                        name: product.name,
                        size: size,
                        quantity: quantity
                    });
                }
            }
        });
    
        setCartItems(updatedCart); // Update the cart with the new or updated items
    
        // Disable inputs for the quantities
        setIsDisabled30x30(true);
        setIsDisabled40x40(true);
        setIsDisabled60x60(true);
    
        // Reset the form and close the modal
        setIsDecreaseFormVisible(false);
        setSelectedSizes([]);
        setQuantity30x30(0);
        setQuantity40x40(0);
        setQuantity60x60(0);
    };

    const closeDecreaseForm = () => {
        setIsDecreaseFormVisible(false);
    };

    // search item filter
    const handleStockSearchChange = (event) => {
        setSearchStockTerm(event.target.value);
    };

    // search history filter
    const handleHistorySearchChange = (event) => {
        setSearchHistoryTerm(event.target.value);
    };

    /*const filteredTransactions = transactions.filter((transaction) =>
        //console.log(transaction)
        transaction.transactionId/*.toLowerCase().includes(searchHistoryTerm.toLowerCase())
    );*/

    const sizes = ['30x30', '40x40', '60x60'];
    const sizeData = sizes.map(size => ({
        label: size,
        value: size
    }));
    
    // Handle selection changes
    const handleSelectionChange = (selected) => {
        if (selected.includes('all')) {
            // Enable all inputs and reset values
            setSelectedSizes(sizeData.map(size => size.value));
            setIsDisabled30x30(false);
            setIsDisabled40x40(false);
            setIsDisabled60x60(false);
        } else {
            setSelectedSizes(selected);
    
            // Enable or disable inputs and clear values accordingly
            if (!selected.includes('30x30')) {
                setIsDisabled30x30(true);
                setQuantity30x30(0);  // Clear value
            } else {
                setIsDisabled30x30(false);
            }
    
            if (!selected.includes('40x40')) {
                setIsDisabled40x40(true);
                setQuantity40x40(0);  // Clear value
            } else {
                setIsDisabled40x40(false);
            }
    
            if (!selected.includes('60x60')) {
                setIsDisabled60x60(true);
                setQuantity60x60(0);  // Clear value
            } else {
                setIsDisabled60x60(false);
            }
        }
    };

    const handleCheckout = async (e) => {
        if (cartItems.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Cart is empty.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }

        //const checkOutButton = e.target;
        //setButtonState(checkOutButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will deduct stocks to the products in the cart.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            //setButtonState(checkOutButton, false, '', '', false);
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const customTransactionDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        const action = "deduct";

        try {
            const { data } = await axios.post(updateProductStockRoute, { action, cartItems: JSON.stringify(cartItems), _user, date, customTransactionDate }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 2500
                });
    
                setCartItems([]);
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    setCartItems([]);
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        //setButtonState(checkOutButton, false, '', '', false); 
    };
    
    const handleEditCartItem = (index) => {
        setEditCartItem({ ...cartItems[index], index }); // Store the item and its index
        setIsEditModalVisible(true); // Show the edit modal
    };

    const handleEditSubmit = (e) => {
        e.preventDefault();
    
        const updatedCart = cartItems.map((item, index) =>
            index === editCartItem.index ? { ...editCartItem } : item
        );
    
        setCartItems(updatedCart); // Update the cart with the modified item
        setIsEditModalVisible(false); // Close the modal
    };
    
    const handleDeleteCartItem = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to remove this item from the cart?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedCart = cartItems.filter((_, i) => i !== index); // Remove the item
                setCartItems(updatedCart);
    
                Swal.fire(
                    'Deleted!',
                    'The item has been removed from your cart.',
                    'success'
                );
            }
        });
    };
    
    const handleClearCart = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to clear all items from the cart?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, clear it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setCartItems([]); // Clear the cart by setting an empty array
                Swal.fire(
                    'Cleared!',
                    'Your cart has been cleared.',
                    'success'
                );
            }
        });
    };

    // Function to handle quotation search input change
    const handleQuotationSearchChange = (e) => {
        setSearchQuotationTerm(e.target.value); // Update the search term for quotations
    };

    // Function to handle when 'Complete' button is clicked
    const handleCompleteClick = async (quotation) => {
        //console.log('Quotation completed:', quotation);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will process this quotation as a completed transaction.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            //setButtonState(checkOutButton, false, '', '', false);
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const quotationId = quotation.quotationId;
        const customTransactionDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        const action = "complete";

        try {
            const { data } = await axios.post(updateQuotationStatusRoute, { action, quotationId, _user, date, customTransactionDate }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 2500
                });
    
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    setCartItems([]);
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    // Function to handle when 'Cancel/Void' button is clicked
    const handleCancelClick = async (quotation) => {
        //console.log('Quotation Voided:', quotation);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will void this quotation, this action is irreversible.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            //setButtonState(checkOutButton, false, '', '', false);
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const quotationId = quotation.quotationId;
        const customTransactionDate = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')} ${String(currentDate.getHours()).padStart(2, '0')}:${String(currentDate.getMinutes()).padStart(2, '0')}:${String(currentDate.getSeconds()).padStart(2, '0')}`;
        const action = "void";

        try {
            const { data } = await axios.post(updateQuotationStatusRoute, { action, quotationId, _user, date, customTransactionDate }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                await Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: data.msg,
                    showConfirmButton: false,
                    timer: 2500
                });
    
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    setCartItems([]);
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    const closeManualDecreaseModal = () => {
        setIsManualDecreaseModalVisible(false);
    };

    const openQuotationModal = (quotation) => {
        setSelectedQuotation(quotation);
        setIsQuotationModalOpen(true);
    };

    const closeQuotationModal = () => {
        setSelectedQuotation(null);
        setIsQuotationModalOpen(false);
    };

    const openTransactionModal = (transaction) => {
        setSelectedTransaction(transaction);
        setIsTransactionModalOpen(true);
    };

    const closeTransactionModal = () => {
        setSelectedTransaction(null);
        setIsTransactionModalOpen(false);
    };


    // Function to handle status filter change
    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    // Apply filter and then paginate the quotations data

    return (
        <div className="adminTransactionPage" style={{ display: enablePage }}>
            <div className="topNavTransaction">
                <h3>Transaction History</h3>
            </div>

            <div className="tabsTransaction">
                <button
                    className={activeTab === 'stocks' ? 'active' : ''}
                    onClick={() => setActiveTab('stocks')}
                    id="tab1"
                >
                    Quotations
                </button>
                <button
                    className={activeTab === 'history' ? 'active' : ''}
                    onClick={() => setActiveTab('history')}
                    id="tab2"
                >
                    Transaction History
                </button>
            </div>

            <div className="transactionContainer">
                {isLoading ? (
                    <div className="loadingAnimationCon">
                        <div className="spinner"></div>
                        <p className="lblLoading">Loading...</p>
                    </div>
                ) : (
                    <>
                        {/* Quotations Table */}
                        {activeTab === 'stocks' && (
                            <div className="stocksTransTableContainer">
                                <h3>Quotation</h3>
                                <div className="actionRow">
                                    <button className="btnManualDecrease" onClick={() => setIsManualDecreaseModalVisible(true)}>
                                        Manual Transaction
                                    </button>
                                    <input
                                        type="text"
                                        placeholder="Search by Quotation ID..."
                                        value={searchQuotationTerm}
                                        onChange={handleQuotationSearchChange}
                                        className="searchQuotationID"
                                    />
                                    <select value={statusFilter} onChange={handleStatusFilterChange} className="statusFilterSelect">
                                        <option value="">All Statuses</option>
                                        <option value="pending">Pending</option>
                                        <option value="completed">Completed</option>
                                        <option value="voided">Voided</option>
                                    </select>
                                </div>

                                <div className='transactionCon1'>
                                    <table className="quotationTable">
                                        <thead>
                                            <tr>
                                                <th>Quotation ID</th>
                                                <th>Date</th>
                                                <th>Expiry Date</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredQuotations.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5">No quotations available.</td>
                                                </tr>
                                            ) : (
                                                paginatedQuotations.map(quotation => (
                                                    <tr key={quotation.quotationId}>
                                                        <td>{quotation.quotationId}</td>
                                                        <td>{new Date(quotation.quotationDate).toLocaleDateString()}</td>
                                                        <td>{new Date(quotation.quotationExpire).toLocaleDateString()}</td>
                                                        <td>{quotation.status}</td>
                                                        <td className='tdButtonQuotation'>
                                                            <button className="viewDetailsButton" onClick={() => openQuotationModal(quotation)}>
                                                                View Details
                                                            </button>
                                                            <button
                                                                className={`completeButton ${quotation.status !== 'pending' ? 'button-disabled' : ''}`}
                                                                onClick={() => handleCompleteClick(quotation)}
                                                                disabled={quotation.status !== 'pending'}
                                                            >
                                                                Complete
                                                            </button>
                                                            <button
                                                                className={`cancelButton ${quotation.status !== 'pending' ? 'button-disabled' : ''}`}
                                                                onClick={() => handleCancelClick(quotation)}
                                                                disabled={quotation.status !== 'pending'}
                                                            >
                                                                Cancel/Void
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button disabled={quotationPage === 1} onClick={goToPrevQuotationPage}>Previous</button>
                                    <span>Page {quotationPage} of {totalQuotationPages}</span>
                                    <button disabled={quotationPage === totalQuotationPages} onClick={goToNextQuotationPage}>Next</button>
                                </div>
                            </div>
                        )}

                        {/* Transaction History Table */}
                        {activeTab === 'history' && (
                            <div className="transactionHistoryTableContainer">
                                <h3>Transaction History</h3>
                                <input
                                    type="text"
                                    placeholder="Search by Transaction ID..."
                                    value={searchHistoryTerm}
                                    onChange={handleHistorySearchChange}
                                    className="searchTransactionID"
                                />

                                <div className='transactionCon2'>
                                    <table className="transactionHistoryTable">
                                        <thead>
                                            <tr>
                                                <th>Transaction ID</th>
                                                <th>Transaction Date</th>
                                                <th>Total Amount (₱)</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paginatedTransactions.length === 0 ? (
                                                <tr>
                                                    <td colSpan="5">No transactions available.</td>
                                                </tr>
                                            ) : (
                                                paginatedTransactions.map(transaction => (
                                                    <tr key={transaction.transactionId}>
                                                        <td>{transaction.transactionId}</td>
                                                        <td>{new Date(transaction.transactionDate).toLocaleString()}</td>
                                                        <td>₱{numFormat.format(parseFloat(transaction.totalAmount))}</td>
                                                        <td>
                                                            <button className='viewDetailsButtonHis' onClick={() => openTransactionModal(transaction)}>View Details</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="pagination">
                                    <button disabled={transactionPage === 1} onClick={goToPrevTransactionPage}>Previous</button>
                                    <span>Page {transactionPage} of {totalTransactionPages}</span>
                                    <button disabled={transactionPage === totalTransactionPages} onClick={goToNextTransactionPage}>Next</button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
    
            {/* Decrease Stock Popup */}
            {isDecreaseFormVisible && product && (
                <div className="popupFormContainerTransaction">
                    <div className="popupFormTransaction">
                        <h2>Decrease Stock</h2>
                        <form className="decreasePopupForm" onSubmit={handleDecreaseSubmit}>
                            <label>
                                Product Code:
                                <span className="productCodeDisplay">{product.code}</span>
                            </label>
                            <label>Size:</label>
                            <TagPicker
                                data={[
                                    { label: 'Select All', value: 'all' },
                                    ...sizeData
                                ]}
                                value={selectedSizes}
                                onChange={handleSelectionChange}
                                style={{ width: 595 }}
                                placeholder="Select Sizes"
                                required
                                searchable={false}
                            />
                            <div className="size-inputs">
                                <label>
                                    30x30:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size30x30"
                                        value={quantity30x30}
                                        disabled={isDisabled30x30} // Disable based on state
                                        style={{
                                            backgroundColor: isDisabled30x30 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity30x30(e.target.value);
                                        }}
                                    />
                                </label>
                                <label>
                                    40x40:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size40x40"
                                        value={quantity40x40}
                                        disabled={isDisabled40x40} // Disable based on state
                                        style={{
                                            backgroundColor: isDisabled40x40 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity40x40(e.target.value);
                                        }}
                                    />
                                </label>
                                <label>
                                    60x60:
                                    <input
                                        type="number"
                                        min={1}
                                        name="size60x60"
                                        value={quantity60x60}
                                        disabled={isDisabled60x60} // Disable based on state
                                        style={{
                                            backgroundColor: isDisabled60x60 ? '#cdcdcd' : 'white'
                                        }}
                                        onInput={(e) => {
                                            setQuantity60x60(e.target.value);
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="formButtons">
                                <button type="submit">
                                    <span className="button-text">Add to Cart</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>
                                <button type="button" onClick={closeDecreaseForm}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {isEditModalVisible && editCartItem && (
                <div className="popupFormContainerTransaction">
                    <div className="popupFormTransaction">
                        <h2>Edit Cart Item</h2>
                        <form onSubmit={handleEditSubmit}>
                            <label>
                                Product Code:
                                <span className="productCodeDisplay">{editCartItem.code}</span>
                            </label>
                            <label>Size:</label>
                            <TagPicker
                                data={sizeData}
                                value={[editCartItem.size]} // Pre-select the current size
                                disabled // Disable size change for simplicity
                            />
                            <label>Quantity:</label>
                            <input
                                type="number"
                                min={1}
                                value={editCartItem.quantity} // Pre-fill with current quantity
                                onChange={(e) => setEditCartItem({ ...editCartItem, quantity: e.target.value })}
                            />
                            <div className="formButtons">
                                <button type="submit">Update</button>
                                <button type="button" onClick={() => setIsEditModalVisible(false)}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {isManualDecreaseModalVisible && (
                <div className="modalOverlay">
                    <div className="manualDecreaseModalContent">
                        <button className="closeButton" onClick={closeManualDecreaseModal}>✖</button>
                        <h2>Manual Transaction</h2>
                        <div className="modalBody">
                            <div className="leftSide" style={{ flex: '65%', marginRight: '20px' }}>
                                <input
                                    type="text"
                                    placeholder="Search by Product Code..."
                                    value={searchStockTerm}
                                    onChange={handleStockSearchChange}
                                    className="searchProductCode"
                                />
                                <table className="stocksTransTable">
                                    <thead>
                                        <tr>
                                            <th>Product Code</th>
                                            <th style={{ width: 200 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedProducts.map((product, index) => (
                                            <tr key={index}>
                                                <td data-label="Product Code">{product.code}</td>
                                                <td style={{ width: 200 }}>
                                                    <button className="decreaseStockButton" onClick={() => handleDecreaseStockClick(product)}>
                                                        Add to Cart
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="pagination">
                                    <button disabled={productPage === 1} onClick={goToPrevProductPage}>Previous</button>
                                    <span>Page {productPage} of {totalProductsPages}</span>
                                    <button disabled={productPage === totalProductsPages} onClick={goToNextProductPage}>Next</button>
                                </div>
                            </div>

                            <div className="rightSide" style={{ flex: '35%', position: 'relative' }}>
                                <div className="cartHeader">
                                    <h3>Cart</h3>
                                    <button className="clearCartButton" onClick={handleClearCart}>Clear Cart</button> {/* Clear Cart button */}
                                </div>
                                <table className="cartTable">
                                    <thead>
                                        <tr>
                                            <th>Product Code</th>
                                            <th>Size</th>
                                            <th>Quantity</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartItems.length > 0 ? (
                                            cartItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.code}</td>
                                                    <td>{item.size}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>
                                                        <button className="editButton" onClick={() => handleEditCartItem(index)}>Edit</button>
                                                        <button className="deleteButton"onClick={() => handleDeleteCartItem(index)}>Delete</button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4">Cart is empty</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <button type="button" className="checkoutButton" id="btnCheckOut" onClick={handleCheckout}>
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {isQuotationModalOpen && selectedQuotation && (
                <div className="modalOverlay">
                    <div className="manualDecreaseModalContent">
                        <button className="closeButton" onClick={closeQuotationModal}>✖</button>
                        <h3>Quotation Details for ID: {selectedQuotation.quotationId}</h3>
                        <p><strong>Date:</strong> {new Date(selectedQuotation.quotationDate).toLocaleString()}</p>
                        <p><strong>Expiry Date:</strong> {new Date(selectedQuotation.quotationExpire).toLocaleString()}</p>
                        <p><strong>Status:</strong> {selectedQuotation.status}</p>
                        <p><strong>Billed to:</strong> {selectedQuotation.userEmail}</p>
                        
                        <h4>Items</h4>
                        <div className="tableWrapper"> {/* Add this wrapper div */}
                            <table className="detailsTable">
                                <thead>
                                    <tr>
                                        <th>Product Code</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Price (₱)</th>
                                        <th>Tax (₱)</th>
                                        <th>Total (₱)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedQuotation.items.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.productCode}</td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>₱{numFormat.format(parseFloat(item.price))}</td>
                                            <td>₱{numFormat.format(parseFloat(item.tax))}</td>
                                            <td>₱{numFormat.format(parseFloat(item.total))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {isTransactionModalOpen && selectedTransaction && (
                <div className="modalOverlay">
                    <div className="manualDecreaseModalContent">
                        <button className="closeButton" onClick={closeTransactionModal}>✖</button>
                        <h3>Transaction Details</h3>
                        <p><strong>Transaction ID:</strong> {selectedTransaction.transactionId}</p>
                        <p><strong>Date:</strong> {new Date(selectedTransaction.transactionDate).toLocaleString()}</p>
                        <p><strong>Total Amount:</strong> ₱{numFormat.format(parseFloat(selectedTransaction.totalAmount))}</p>

                        <div className="tableWrapper"> {/* Add this wrapper div */}
                            <table className="detailsTable">
                                <thead>
                                    <tr>
                                        <th>Product Code</th>
                                        <th>Product Name</th>
                                        <th>Size</th>
                                        <th>Quantity</th>
                                        <th>Price (₱)</th>
                                        <th>Tax (₱)</th>
                                        <th>Total (₱)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedTransaction.items.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.productCode}</td>
                                            <td>{item.productName}</td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>₱{numFormat.format(parseFloat(item.price))}</td>
                                            <td>₱{numFormat.format(parseFloat(item.tax))}</td>
                                            <td>₱{numFormat.format(parseFloat(item.total))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TransactionPage;