import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CsrfTokenContext } from './csrfContext';
import axios from "axios";
import Swal from 'sweetalert2';
import { TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 
import '../assets/styles/productCard.css';
import { host, updateProductRoute, updateProductImageRoute, disableEnableProductRoute } from '../utils/APIRoutes';

const ProductCard = ({ product, categories, fetchData }) => {
    const { csrfToken } = useContext(CsrfTokenContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isImagePopupVisible, setIsImagePopupVisible] = useState(false);
    const [formData, setFormData] = useState({
        productId: product.id,
        productName: product.name,
        categories: product.categories,
        productPrices: product.prices,
        productCode: product.code,
        description: product.description
    });
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [price30x30, setPrice30x30] = useState(0);
    const [price40x40, setPrice40x40] = useState(0);
    const [price60x60, setPrice60x60] = useState(0);
    const categoryIndex = categories.findIndex(category => {
        const categoriesObj = JSON.parse(formData.categories);
        let result = false;
        categoriesObj.categories?.forEach((categoryData, index) => {
            result = category.id === categoryData;
        });

        return result;
    });

    useEffect(() => {
        const objPrices = JSON.parse(product.prices);
        objPrices.prices.forEach((price) => {
            for (let size in price) {
                if (size === "30x30") {
                    setPrice30x30(parseFloat(price[size]));
                } else if (size === "40x40") { 
                    setPrice40x40(parseFloat(price[size]));
                } else if (size === "60x60") {
                    setPrice60x60(parseFloat(price[size]));
                }
            }
            
        });
    }, [product.prices]);

    const isBtnDisabled = categories[categoryIndex]?.status === 'true' ? true : false;

    const [imageFile, setImageFile] = useState();
    const navigate = useNavigate();

    const handleUpdateClick = () => {
        setIsPopupVisible(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Custom handle for category changes in order to convert the array into an object and serialize.
    const handleCategoriesChange = (e) => {
        const categoriesObj = { categories: e };
        setFormData((prevData) => ({
            ...prevData,
            categories: JSON.stringify(categoriesObj),
        }));
    };

    const setButtonState = (button, isDisabled, backgroundColor = '', textColor = '', showSpinner = false) => {
        const buttonText = button.querySelector('.button-text');
        const spinner = button.querySelector('.spinner');
    
        button.disabled = isDisabled;
        button.style.backgroundColor = backgroundColor;
        button.style.color = textColor;
    
        buttonText.style.display = showSpinner ? 'none' : 'inline-block';
        spinner.style.display = showSpinner ? 'inline-block' : 'none';
    };

    const displayArr = (obj) => {
        return obj?.join(", ");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const submitButton = e.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will update this product with the information you provided.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        const pricesObj = JSON.parse(formData.productPrices);
        let currentSizes = [];
        let pricesValid = true;

        pricesObj.prices.forEach(price => {
            for (let size in price) {
                if (parseFloat(price[size]) <= 0) {
                    currentSizes.push(size);
                    pricesValid = false;
                }
            }
        });

        if (!pricesValid) {
            setButtonState(submitButton, false, '', '', false);

            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: "Error",
                text: `Price too low for ${displayArr(currentSizes)}. Please try again.`,
                showConfirmButton: false,
                timer: 3500
            });
        }

        // Update Product
        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();

        try {
            const { data } = await axios.post(updateProductRoute, { selectedSizes, _user, date, ...formData }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Product Updated Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                setIsPopupVisible(false);
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    setIsPopupVisible(false);
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
        
        setButtonState(submitButton, false, '', '', false);
    };

    const handleCancel = () => {
        setIsPopupVisible(false);
    };

    const handleUpdateImageClick = () => {
        setIsImagePopupVisible(true);
    };
    
    const handleImagePopupClose = () => {
        setIsImagePopupVisible(false);
    };
    
    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };
    
    // upload update image
    const handleImageSubmit = async (e) => {
        e.preventDefault();

        const submitButton = e.target.querySelector('button[type="submit"]');
        setButtonState(submitButton, true, '#FFFF00', '#000000', true);

        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will replace the this product image with the image you provided. This action is irreversible.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
          }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            setButtonState(submitButton, false, '', '', false);
            return;
        }

        if (imageFile?.size > 10485760) {
            return Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Image file too large, please keep it under 10MB.',
                showConfirmButton: false,
                timer: 3500
            });
        }

        // Update Product Image
        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const multiFormData = new FormData();

        multiFormData.append('productId', product.id);
        multiFormData.append('productCode', product.code);
        multiFormData.append('imageDbPath', product.image);
        multiFormData.append('categories', product.categories);
        multiFormData.append('image', imageFile);
        multiFormData.append('_user', _user.username);
        multiFormData.append('date', date);

        try {
            const { data } = await axios.post(updateProductImageRoute, multiFormData, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Product Image Updated Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                setIsImagePopupVisible(false);
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    setIsImagePopupVisible(false);
                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }

        setButtonState(submitButton, false, '', '', false);
    };

    const handleDisableEnableProduct = async (action) => {
        // Enable/Disable Product
        const confirmation = await Swal.fire({
            title: "Are you sure?",
            text: `This will ${action ? "disable" : "enable"} this product.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm"
        }).then((result) => {
            return result.isConfirmed;
        });

        if (!confirmation) {
            return;
        }

        const _user = JSON.parse(window.localStorage.getItem("user"));
        const currentDate = new Date();
        const date = currentDate.toLocaleString();
        const productId = product.id;
        const categories = product.categories;
        const productCode = product.code;

        try {
            const { data } = await axios.post(disableEnableProductRoute, { productId, productCode, categories, action, _user, date }, {
                headers: {
                    'X-CSRF-Token': csrfToken, // Include CSRF token in headers
                },
                withCredentials: true, // Ensures cookies are sent and received
            });
    
            if (data.status === true) {      
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Product State Changed Successfully!',
                    showConfirmButton: false,
                    timer: 2500
                });
    
                fetchData();
            } else {
                Swal.fire(
                    "Error!",
                    data.msg,
                    "error"
                );
            }
        } catch (error) {
            // Check if the error response exists (server returned a response)
            if (error.response) {
                const status = error.response.status;
                const responseData = error.response.data; // The server's response data

                // Handle different status codes
                if (status === 403) {
                    await Swal.fire({
                        icon: 'error',
                        title: 'Access Denied!',
                        text: 'Your session may have expired.',
                        timer: 3000,
                        timerProgressBar: true
                    });

                    window.localStorage.clear();
                    navigate('/portal/login');
                } else if (status === 404) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Not Found!',
                        text: 'The requested resource could not be found.',
                    });
                } else {
                    // Handle other errors or status codes
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: responseData.msg || 'An unexpected error occurred.',
                    });
                }
            } else if (error.request) {
                // If no response was received from the server
                Swal.fire({
                    icon: 'error',
                    title: 'Network Error!',
                    text: 'No response from the server. Please try again later.',
                });
            } else {
                // Handle any other errors during the request setup
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something went wrong. Please try again.',
                });
            }
        }
    };

    const imageStyle = {
        width: formData.imageWidth || '130px', 
        height: formData.imageHeight || '130px',
        objectFit: 'cover',
    };

    const displayPricesArr = (arr) => {
        let arrToDisplay = [];

        arr.forEach((value) => {
            for (let size in value) {
                arrToDisplay.push(`${size}: ₱${value[size]}`);
            }
        });

        return arrToDisplay.join(", ");
    }

    const displayStocksArr = (arr) => {
        let arrToDisplay = [];

        arr.forEach((value) => {
            for (let size in value) {
                arrToDisplay.push(`${size}: ${value[size]}`);
            }
        });

        return arrToDisplay.join(", ");
    }

    const handlePricesChange = (currentSize, value) => {
        let objPrices = { prices: [] };

        selectedSizes.forEach((size) => {
            if (size === "30x30") {
                objPrices.prices.push({ [size]: price30x30 });
            } else if (size === "40x40") {
                objPrices.prices.push({ [size]: price40x40 });
            } else if (size === "60x60") {
                objPrices.prices.push({ [size]: price60x60 });
            }
        });

        objPrices.prices.forEach((price) => {
            if (Object.keys(price).toString() === currentSize) {
                price[currentSize] = value;
            }
        });

        setFormData((prevData) => ({
            ...prevData,
            productPrices: JSON.stringify(objPrices),
        }));
    };

    const sizes = ['30x30', '40x40', '60x60'];
    const sizeData = sizes.map(size => ({
        label: size,
        value: size
    }));
    
    const [isDisabled30x30, setIsDisabled30x30] = useState(true);
    const [isDisabled40x40, setIsDisabled40x40] = useState(true);
    const [isDisabled60x60, setIsDisabled60x60] = useState(true);
    
    // Handle selection changes
    const handleSelectionChange = (selected) => {
        if (selected.includes('all')) {
            // Enable all inputs and reset values
            setSelectedSizes(sizeData.map(size => size.value));
            setIsDisabled30x30(false);
            setIsDisabled40x40(false);
            setIsDisabled60x60(false);
        } else {
            setSelectedSizes(selected);
    
            // Enable or disable inputs and clear values accordingly
            if (!selected.includes('30x30')) {
                setIsDisabled30x30(true);
                setPrice30x30(price30x30);  // Clear value
            } else {
                setIsDisabled30x30(false);
            }
    
            if (!selected.includes('40x40')) {
                setIsDisabled40x40(true);
                setPrice40x40(price40x40);  // Clear value
            } else {
                setIsDisabled40x40(false);
            }
    
            if (!selected.includes('60x60')) {
                setIsDisabled60x60(true);
                setPrice60x60(price60x60);  // Clear value
            } else {
                setIsDisabled60x60(false);
            }
        }
    };
    
    return (
        <div className="productCard">
        <div className="productCardHeader">
            {/* Product Image */}
            <img src={`${host}/static/${product.image}`} alt={product.name} className="productCardImage" style={imageStyle}/>
    
            {/* Product Details */}
            <div className="productInfo">
                <h3 className="productCardTitle">{product.name}</h3>
                <div className="productDetailsTwoColumns">
                        {/* First Column */}
                        <div className="productDetailsColumn">
                            <p className="productDetailTitle">Prices</p>
                            <p className="productCardPrice">{displayPricesArr(JSON.parse(product.prices).prices)}</p>
                        </div>
                        
                        {/* Second Column */}
                        <div className="productDetailsColumn2">
                            <p className="productDetailTitle">Stocks</p>
                            <p>{displayStocksArr(JSON.parse(product.stocks).stocks)}</p>
                        </div>
                    </div>
                <p>{isBtnDisabled ? 'Category Disabled' : product.status === 'false' ? 'Enabled' : 'Disabled'}</p>
            </div>
        </div>
    
        {/* Product Buttons */}
        <div className="productCardButtons">
            <button className={isBtnDisabled ? 'btnDisabled' : 'btnUpdate'} disabled={isBtnDisabled} onClick={handleUpdateClick}>Update</button>
            <button className={isBtnDisabled ? 'btnDisabled' : 'btnUpdateImg'} disabled={isBtnDisabled} onClick={handleUpdateImageClick}>Update Image</button>
            <button className={isBtnDisabled ? 'btnDisabled' : product.status === 'false' ? 'disableButton' : 'enableButton'} disabled={isBtnDisabled} onClick={() => handleDisableEnableProduct((product.status === "false"))}>
                {product.status === 'false' ? 'Disable' : 'Enable'}
            </button>
        </div>
            {/* update product */}
            {isPopupVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Update Product</h2>
                        <form onSubmit={handleSubmit}>
                            <label>
                                Product Name:
                                <input type="text" name="productName" value={formData.productName} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Product Code:
                                <input type="text" name="productCode" value={formData.productCode} onChange={handleInputChange} required />
                            </label>
                            <label>
                                Product Description:
                                <textarea name="description" value={product.description} onChange={handleInputChange} required> </textarea> 
                            </label>
                            {/* <label>
                                Size:
                                <select name="productSize" value={formData.productSize} onChange={handleInputChange} required>
                                    <option value="" disabled>Select size</option>
                                    <option value="30x30">30x30</option>
                                    <option value="40x40">40x40</option>
                                    <option value="60x60">60x60</option>
                                </select>
                            </label> */}
                            <label>Category:</label>
                            <label>
                                <TagPicker
                                    data={categories
                                        .filter(category => category.status !== "true")
                                        .map(category => ({
                                            label: category.name,
                                            value: category.id
                                        }))
                                    }
                                    defaultValue={JSON.parse(formData.categories).categories || []} // This will allow an array of selected categories
                                    onChange={handleCategoriesChange} 
                                    style={{ width: 1000 }}
                                    placeholder="Select Categories"
                                    required
                                    searchable={false}
                                />
                            </label>
                            {/* <label>
                                Price:
                                <input type="number" name="productPrice" value={formData.productPrice} onChange={handleInputChange} required />
                            </label> */}

                            <br />
                            <label>
                            <TagPicker
                                    data={[
                                        { label: 'Select All', value: 'all' },
                                        ...sizeData
                                    ]}
                                    value={selectedSizes}
                                    onChange={handleSelectionChange}
                                    style={{ width: 500 }}
                                    placeholder="Select Sizes"
                                    required
                                    searchable={false}
                                />
                            </label>
                            <br />
                            <label>Prices</label>
                            <div className="sizeInputsContainer">
                                <div className="sizeInput">
                                <label>
                                30x30:
                                <input
                                    type="number"
                                    step="0.01"
                                    min={1}
                                    name="size30x30"
                                    value={price30x30}
                                    disabled={isDisabled30x30}
                                    style={{
                                        backgroundColor: isDisabled30x30 ? '#cdcdcd' : 'white'
                                    }}
                                    onInput={(e) => {
                                        setPrice30x30(e.target.value);
                                        
                                        handlePricesChange("30x30", e.target.value);
                                    }}
                                />
                            </label>
                                </div>
                                <div className="sizeInput">
                                <label>
                                40x40:
                                <input
                                    type="number"
                                    step="0.01"
                                    min={1}
                                    name="size40x40"
                                    value={price40x40}
                                    disabled={isDisabled40x40}
                                    style={{
                                        backgroundColor: isDisabled40x40 ? '#cdcdcd' : 'white'
                                    }}
                                    onInput={(e) => {
                                        setPrice40x40(e.target.value);
                                        handlePricesChange("40x40", e.target.value);
                                    }}
                                />
                            </label>
                                </div>
                                <div className="sizeInput">
                                <label>
                                60x60:
                                <input
                                    type="number"
                                    step="0.01"
                                    min={1}
                                    name="size60x60"
                                    value={price60x60}
                                    disabled={isDisabled60x60}
                                    style={{
                                        backgroundColor: isDisabled60x60 ? '#cdcdcd' : 'white'
                                    }}
                                    onInput={(e) => {
                                        setPrice60x60(e.target.value);
                                        handlePricesChange("60x60", e.target.value);
                                    }}
                                />
                            </label>
                                </div>
                            </div>
                            <div className='productCardForm'>
                                <button type="submit">
                                        <span className="button-text">Submit</span>
                                        <span className="spinner" style={{ display: 'none' }}></span>
                                    </button>                                 
                                <button type="button" className="btnClose" onClick={handleCancel}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {/* update form for image only */}
            {isImagePopupVisible && (
                <div className="popupFormContainer">
                    <div className="popupForm">
                        <h2>Update Product Image</h2>
                        <form onSubmit={handleImageSubmit}>
                            <label>
                                Product Image:
                                <input type="file" name="image" accept="image/png, image/jpeg" onChange={handleImageChange} />
                            </label>
                            <div className='productCardForm'>
                                <button type="submit">
                                    <span className="button-text">Submit</span>
                                    <span className="spinner" style={{ display: 'none' }}></span>
                                </button>                                
                                <button type="button" className="btnClose" onClick={handleImagePopupClose}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductCard;
