export const host = "https://api.jcjhomebuilders.online";
export const tokenRoute = `${host}/api/portal/auth/getToken`;
export const logOutRoute = `${host}/api/portal/auth/PORTAL_logout`;
export const loginRoute = `${host}/api/portal/auth/PORTAL_login`;
export const fetchProductsDataRoute = `${host}/api/portal/auth/PORTAL_fetch_products_data`;
export const fetchStocksDataRoute = `${host}/api/portal/auth/PORTAL_fetch_stocks_data`;
export const fetchLogsDataRoute = `${host}/api/portal/auth/PORTAL_fetch_logs_data`;
export const createProductRoute = `${host}/api/portal/auth/PORTAL_add_product`;
export const createCategoryRoute = `${host}/api/portal/auth/PORTAL_add_category`;
export const updateProductRoute = `${host}/api/portal/auth/PORTAL_update_product`;
export const updateProductImageRoute = `${host}/api/portal/auth/PORTAL_update_product_image`;
export const updateProductStockRoute = `${host}/api/portal/auth/PORTAL_update_product_stock`;
export const updateQuotationStatusRoute = `${host}/api/portal/auth/PORTAL_update_quotation_status`;
export const updateCategoryRoute = `${host}/api/portal/auth/PORTAL_update_category`;
export const disableEnableProductRoute = `${host}/api/portal/auth/PORTAL_disable_enable_product`;
export const disableEnableCategoryRoute = `${host}/api/portal/auth/PORTAL_disable_enable_category`;