import React, { useState, useEffect, useContext } from 'react';
import '../assets/styles/loginPage.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { loginRoute } from '../utils/APIRoutes';
import { CsrfTokenContext } from './csrfContext';
import Swal from 'sweetalert2';
import logo from '../assets/images/logo.png';

const LoginPage = () => {
  const { csrfToken, fetchCsrfToken } = useContext(CsrfTokenContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const isLoggedIn = window.localStorage.getItem("user");

  const [isDisabled, setIsDisabled] = useState(false);
  const [attempts, setAttempts] = useState(3);
  const [cooldown, setCooldown] = useState(1);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/portal/product-management");
    } else {
      fetchCsrfToken();
      document.title = "JCJHomeBuilders Portal - Log In";
    }
  }, [isLoggedIn, fetchCsrfToken, navigate]);

  useEffect(() => {
    let cooldownInterval;

    if (isDisabled && cooldown > 0) {
      cooldownInterval = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
    } else if (cooldown === 0 && isDisabled) {
      setIsDisabled(false);
      setAttempts(3); // Reset attempts after cooldown
    }

    return () => clearInterval(cooldownInterval);
  }, [isDisabled, cooldown]);

  const setButtonState = (button, isDisabled, backgroundColor = '', textColor = '', showSpinner = false) => {
    const buttonText = button.querySelector('.button-text');
    const spinner = button.querySelector('.spinner');

    button.disabled = isDisabled;
    button.style.backgroundColor = backgroundColor;
    button.style.color = textColor;

    buttonText.style.display = showSpinner ? 'none' : 'inline-block';
    spinner.style.display = showSpinner ? 'inline-block' : 'none';
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    
    const submitButton = e.target.querySelector('button[type="submit"]');
    setButtonState(submitButton, true, '#007bff', '#000000', true);

    if (isDisabled) return;

    try {
      const {data} = await axios.post(loginRoute, {
        username,
        password
      }, {
        headers: {
          'X-CSRF-Token': csrfToken, // Include CSRF token in headers
        },
        withCredentials: true, // Ensures cookies are sent and received
      });
  
      if (data.status === true) {
        window.localStorage.setItem("user", JSON.stringify(data.data[0]));
  
        await Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Logged In Successfully!',
          showConfirmButton: false,
          timer: 1500
        });
  
        navigate("/");
      } else {
        setAttempts((prevAttempts) => {
          const newAttempts = prevAttempts - 1;
          if (newAttempts <= 0) {
            // Trigger cooldown and disable button
            setIsDisabled(true);
            setCooldown(90); // Start cooldown for 30 seconds
            Swal.fire(
              "Too many login attempts!",
              "Please try again after 1 minute and 30 seconds.",
              "error"
            );
          } else {
            Swal.fire(
              "Unable to Log In!",
              "Incorrect Username or Password",
              "warning"
            );
          }
          return newAttempts;
        });
      }
    } catch (error) {
      // Check if the error response exists (server returned a response)
      if (error.response) {
        const status = error.response.status;
        const responseData = error.response.data; // The server's response data

        // Handle different status codes
        if (status === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Something went wrong. Please try again.',
          });
        } else {
          // Handle other errors or status codes
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: responseData.msg || 'An unexpected error occurred.',
          });
        }
      } else if (error.request) {
        // If no response was received from the server
        Swal.fire({
          icon: 'error',
          title: 'Network Error!',
          text: 'No response from the server. Please try again later.',
        });
      } else {
        // Handle any other errors during the request setup
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Something went wrong. Please try again.',
        });
      }
    }

    setButtonState(submitButton, false, '', '', false);
  };

  return (
    <div className="loginPage">
      <div className="logFormDiv">
      <img src={logo} alt="Logo" className='logo'/>
        <form id="loginForm" onSubmit={onSubmit}>
          <div className="inputCon">
            <input
              type="text"
              id="txtUsernameLog"
              className={`txtUsernameLog ${isDisabled && attempts <= 0 ? 'grey' : ''}`}
              placeholder="Enter your username"
              onChange={(e) => setUsername(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <div className="inputCon">
            <input
              type={showPassword ? "text" : "password"}
              id="txtPasswordLog"
              className={`txtPasswordLog ${isDisabled && attempts <= 0 ? 'grey' : ''}`}
              placeholder="Enter your password"
              onChange={(e) => setPassword(e.target.value)}
              disabled={isDisabled}
            />
          </div>
          <div className="showPasswordCon">
            <input
              type="checkbox"
              id="showPassword"
              checked={showPassword}
              onChange={handleShowPasswordToggle}
              disabled={isDisabled}
            />
            <label htmlFor="showPassword">Show Password</label>
          </div>
          <br />
          <button type="submit" className={`btnLogIn ${isDisabled && attempts <= 0 ? 'red' : ''}`}>
            <span className="button-text">Login</span>
            <span className="spinner" style={{ display: 'none' }}></span>
          </button>
        </form>
        {attempts < 3 && !isDisabled && (
          <p>{`You have ${attempts} attempt(s) left.`}</p>
        )}

        {isDisabled && (
          <p>{`Please wait ${cooldown} seconds to try again.`}</p>
        )}
      </div>
    </div>
  );  
};

export default LoginPage;
